<template>
  <AppPageCard>
    <template #card-title> {{ $t("ENTITLEMENT.DETAILS")}} </template>

    <div>
      <span
        class="
          text-muted
          font-weight-bold font-size-base
          d-none d-md-inline
          mr-1
        "
      >
        {{ $t("TEACHER.EMP") }}
      </span>
      <span
        class="
          text-dark-50
          font-weight-bolder font-size-base
          d-none d-md-inline
          mr-3
        "
      >
        {{ getTitle() }}
      </span>
      <IndividualEmployeeLeaveEntitlementTable
        :is-busy="isBusy"
        :items="items"
        :pagination="pagination"
        @onClickEntitlement="showModal"
        @row-clicked="showModal"
      ></IndividualEmployeeLeaveEntitlementTable>

      <TeacherIndividualEntitlementModal
        :is-busy="isBusy"
        :assigned-leave="form"
        @formSubmitted="fetch"
      ></TeacherIndividualEntitlementModal>
    </div>
  </AppPageCard>
</template>

<script>
import { mapGetters } from "vuex";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import IndividualEmployeeLeaveEntitlementTable from "@/modules/school/components/management/leave/entitlement/IndividualEmployeeLeaveEntitlementTable";
import TeacherIndividualEntitlementModal from "@/modules/school/components/management/leave/entitlement/teacher/TeacherIndividualEntitlementModal";

export default {
  name: "TeacherIndividualEntitlementPage",
  components: {
    TeacherIndividualEntitlementModal,
    IndividualEmployeeLeaveEntitlementTable,
    AppPageCard,
  },
  data() {
    return {
      isBusy: false,
      selectedLeave: this.form ?? {},
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    getTitle() {
      return this.$route.query.name;
    },

    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchTeacherIndividualEntitlementList", {
          id: this.$route.query.id,
        })
        .finally(() => (this.isBusy = false));
    },

    /**
     *
     * @param data
     */
    showModal(data) {
      console.log("Data ", data);

      this.isBusy = true;
      this.$store
        .dispatch("fetchTeacherIndividualEntitlementForm", {
          id: data.id,
        })
        .finally(() => (this.isBusy = false));

      this.$bvModal.show("leave-page-entitlement-modal");
    },
  },
  computed: {
    ...mapGetters({
      items: "getTeacherIndividualEntitlementList",
      pagination: "getTeacherIndividualEntitlementListPagination",
      form: "getTeacherIndividualEntitlementForm",
    }),
  },
};
</script>

<style scoped></style>
